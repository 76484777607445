import * as yup from "yup"

export const newDocumentSchema = yup.object().shape({
	// url_link: yup.string()
	// 			.required("is jodidamente required")
	// 			.matches(
	// 				/(https:\/\/)?(drive\.\google\.\com\/file | docs\.\google\.\com\/document)?\/.*/,
	// 				'Enter correct url!'
	// 			),
	url_link: yup.string()
				.required("is required"),
	type: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    })
})


export const destinationSchema = yup.object().shape({
	type: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    })
})


export const yearForDocchema = yup.object().shape({
	year: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    })
})

export const newCareersDocSchema = newDocumentSchema.concat(yearForDocchema)

export const subjectSchema = yup.object().shape({
	achievement: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    })
})

let patternTwoDigisAfterComma = /^\d+(\.\d{0,1})?$/;
let patternOnlyNumberOneDecimal = /^([0-9]\d*(?:\.\d{0,1})?)?$/;
const commonStringValidator = yup
	// .number('must be a number')
	// .positive('must be positive')
	//.string()
	.number().test(
		"is-decimal",
		"positive number with maximum one decimal",
		(val) => {
			//console.log(`validation val ${val}`)
		if (val != undefined) {
			return patternOnlyNumberOneDecimal.test(val);
		}
		return true;
		}
	).typeError('must be a number')
	.min(0, "minimum 0").required('is required')
	// .typeError('must be a number')
	// .required("Is required");

export const editProgrammSchema = yup.object().shape({
	programme: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    }),
	speciality: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    }),
	weeklyHours: commonStringValidator,
	learnerWeeks: commonStringValidator,
	weeklyDays: commonStringValidator,
	//totalHours: commonStringValidator,
	//qualyHours: commonStringValidator,
	//eppHours: commonStringValidator

	eppHours: yup.number().when(['programme'], {
        is: (programme) => 
            {
				//referenceMonth !== `${new Date(openingDate).getMonth()}`,
				//alert("puede que si??")
				//alert(programme.value)
				if(programme.value == 1){
					//alert(`PRIMERO`)
					return true;
				}
			},
        then: yup.number().required('is required')
    }),

},[['programme','eppHours']])


export const datesForProgramSchema = yup.object().shape({
	startDate: yup.date().required(),
	endDate: yup.date().required(),
})

export const addProgrammSchema = editProgrammSchema.concat(datesForProgramSchema)

export const addAssessmentSchema = yup.object().shape({
	
	mathsExem: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    }),
	engExem: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    }),
})

export const newMedicalSchema = yup.object().shape({
	condition: yup.array()
      .min(1, 'Pick at least 1 option')
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      ),
	// condition: yup.object().shape({
    // 	label: yup.string().required(),
    // 	value: yup.string().required()
    // })
	// .nullable()
    // .required(),
})

export const newTaxiSchema = yup.object().shape({
	company: yup.string().required(),
	email: yup.string().email().required(),
	// contact_number: yup.string().required()
	contact_number: yup
		.string()
		.required("is required")
		.matches(/^(((01|02|03|07|08)\d{2,3})|(\d{5}))(\s|-|.)(((\d{3,4})(\s|-)(\d{3,4}))|((\d{6,7})))$/,
								"is not valid"),
})

export const registerSchema = yup.object().shape({
    name: yup.string().required(),
    surname: yup.string().required(),
	//email: yup.string().email().required(),
    email: yup.string().email().matches(/\@rootsandshoots.org.uk$/, 'Domain not allowed'),
	password: yup
		.string().trim()
		.required("Please enter your password")
		.matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
					"Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
    //age: yup.number().positive().integer().required(),
    //password: yup.string().min(4).max(15).required(),
    // oneOf compares with other field defined here in the schema 
    confirmPassword: yup.string().oneOf([yup.ref("password"),null]),
    retEmail: yup.string().oneOf([yup.ref("email"),null]),

})


export const editStaffSchema = yup.object().shape({
	name: yup.string().required(),
    surname: yup.string().required(),
	//nokContact_number: yup.string().required(),
	position: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    }),
	roleToShow: yup.array()
      .min(1, 'Pick at least 1 option')
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      ),
})


export const nokSchema = yup.object().shape({
	nokName: yup.string().required(),
    nokSurname: yup.string().required(),
	//nokContact_number: yup.string().required(),
	nokContact_number: yup
		.string()
		.required("is required")
		.matches(/^(((01|02|03|07|08)\d{2,3})|(\d{5}))(\s|-|.)(((\d{3,4})(\s|-)(\d{3,4}))|((\d{6,7})))$/,
								"is not valid"),
	kinship: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    })
})

export const nokSchemaModal = yup.object().shape({
	name: yup.string().required(),
    surname: yup.string().required(),
	//contact_number: yup.string().required(),
	contact_number: yup
		.string()
		.required("is required")
		.matches(/^(((01|02|03|07|08)\d{2,3})|(\d{5}))(\s|-|.)(((\d{3,4})(\s|-)(\d{3,4}))|((\d{6,7})))$/,
								"is not valid"),
	kinship: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    }),
	address: yup.object().shape({
    	address1: yup.string().required(),
    	postcode: yup
				.string()
				.required("is required")
				.matches(/\b(?:[A-Za-z][A-HJ-Ya-hj-y]?[0-9][0-9A-Za-z]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})\b/ , "is not valid")

    })
})

export const supportSchemaModal = yup.object().shape({
	name: yup.string().required(),
    // surname: yup.string().required(),
	// contact_number: yup
	// 				.string()
	// 				.required("is required")
	// 				.matches(/^(((01|02|03|07|08)\d{2,3})|(\d{5}))(\s|-|.)(((\d{3,4})(\s|-)(\d{3,4}))|((\d{6,7})))$/,
	// 							"is not valid"),
    // address1: yup.string().required(),
    // postcode: yup
	// 			.string()
	// 			.required("is required")
	// 			.matches(/\b(?:[A-Za-z][A-HJ-Ya-hj-y]?[0-9][0-9A-Za-z]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})\b/ , "is not valid")

})

export const personalDataStaff = yup.object().shape({
	name: yup.string().required(),
    surname: yup.string().required(),
	position: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    })
})


export const changePwSchema = yup.object().shape({
	current: yup.string().required(),
	retypeNew: yup.string().required(),
	newPassword: yup
		.string().trim()
		.required("is required")
		.matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
					"must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
    
    // oneOf compares with other field defined here in the schema 
    retypeNew: yup.string().oneOf([yup.ref("newPassword"),null]),
	
})

export const personalDataStudentSchema = yup.object().shape({
    name: yup.string().required(),
    surname: yup.string().required(),
	email: yup.string().email().required(),
	contact_number: yup
		.string()
		.required("is required")
		.matches(/^(((01|02|03|07|08)\d{2,3})|(\d{5}))(\s|-|.)(((\d{3,4})(\s|-)(\d{3,4}))|((\d{6,7})))$/,
								"is not valid"),
		// .matches(/^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
        // 			"is not valid"),
	ethnicity: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    })
	.nullable() // for handling null value when clearing options via clicking "x"
    .required(),
    dob: yup.date().required(),
	gender: yup.object().shape({
    	label: yup.string().required(),
    	value: yup.string().required()
    })
    .nullable()
    .required(),

	// passport_number: yup.string(),
	// passExpi: yup.date(),
	// cert_path: yup.string(),

	cert_path: yup.string().when(['passExpi', 'pass_path'], {
		is: (passExpi, pass_path) => !passExpi || !pass_path,
		then: yup.string().required('You must add at least either all passport information or birth certificate information or both')
	}),

	passExpi: yup.string().when(['cert_path'], {
		is: (cert_path) => !cert_path,
		then: yup.string().required('You must add at least either all passport information or birth certificate information or both')
	}),
	
	pass_path: yup.string().when(['cert_path'], {
		is: (cert_path) => !cert_path,
		then: yup.string().required('You must add at least either all passport information or birth certificate information or both')
	}),

	passport_number: yup.string().when(['cert_path'], {
		is: (cert_path) => !cert_path,
		then: yup.string().required('You must add at least either all passport information or birth certificate information or both')
	}),

	borough: yup.object().shape({
    	value: yup.string().required()
    }) 
	.nullable()
    .required(),

},[['cert_path','passExpi'], ['cert_path','pass_path']])



export const addCareersSchema = yup.object().shape({
	
	cv_link: yup.string().when(['voc_link'], {
		is: (voc_link) => !voc_link,
		then: yup.string().required('Add at least one of the document links')
	}),

	voc_link: yup.string().when(['cv_link'], {
		is: (cv_link) => !cv_link,
		then: yup.string().required('Add at least one of the document links')
	}),
},[['cv_link','voc_link']])



export const personalDataStudentToEditSchema = yup.object().shape({
    name: yup.string().required(),
    surname: yup.string().required(),
	nin: yup.string()
			.required("is required")
			.matches(/^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]?\s*$/, "is not valid"),
	//lrs: yup.string().required(),
	//passport_number: yup.string().required(),
	email: yup.string().email().required(),
	contact_number: yup
		.string()
		.required("is required")
		.matches(/^(((01|02|03|07|08)\d{2,3})|(\d{5}))(\s|-|.)(((\d{3,4})(\s|-)(\d{3,4}))|((\d{6,7})))$/,
								"is not valid"),

})

export const contactInfoSchema = yup.object().shape({

	address: yup.object().shape({
    	address1: yup.string().required(),
    	postcode: yup
					.string()
					.required("is required")
					.matches(/\b(?:[A-Za-z][A-HJ-Ya-hj-y]?[0-9][0-9A-Za-z]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})\b/ , "is not valid")

    })
    .nullable() // for handling null value when clearing options via clicking "x"
    .required(),
	

})

export const contactInfoNokSchema = yup.object().shape({

	nokAddress: yup.object().shape({
    	address1: yup.string().required(),
    	//postcode: yup.string().required(),
		postcode: yup
					.string()
					.required("is required")
					.matches(/\b(?:[A-Za-z][A-HJ-Ya-hj-y]?[0-9][0-9A-Za-z]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})\b/ , "is not valid")

    })
    .nullable() // for handling null value when clearing options via clicking "x"
    .required(),
	

})

export const loginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
})

export const resetPwSchema = yup.object().shape({
	password: yup
		.string().trim()
		.required("is required")
		.matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
					"must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
    password2: yup.string().required(),
    // oneOf compares with other field defined here in the schema 
    password2: yup.string().oneOf([yup.ref("password"),null]),
})

export const forgotSchema = yup.object().shape({
    email: yup.string().email().required()
})

export const ehcpSchema = yup.object().shape({
    ehcpDate: yup.string().required(),
    lastAnualReview: yup.string().required(),
	doc_link: yup.string().required()
})

export const addStudentSchema = personalDataStudentSchema.concat(contactInfoSchema).concat(nokSchema).concat(contactInfoNokSchema);

export const editStudentSchema = personalDataStudentToEditSchema.concat(contactInfoSchema);

export const addStaffSchema = personalDataStaff.concat(contactInfoSchema);

export const addNokSchema = nokSchema.concat(contactInfoNokSchema);
