import { messageResources } from "./messageResources"


export const learnDificOptions = [  
    { value: '00', label: 'None' },
    { value: '04', label: 'Visual impairment' },
    { value: '05', label: 'Hearing impairment' },
    { value: '06', label: 'Disability affecting mobility' },
    { value: '07', label: 'Profound complex disabilities' },
    { value: '08', label: 'Social and emotional disabilities' },
    { value: '09', label: 'Mental Health difficulty' },
    { value: '10', label: 'Moderate LD' },
    { value: '11', label: 'Severe LD' },
    { value: '12', label: 'Dyslexia' },
    { value: '13', label: 'Dyscalculia' },
    { value: '14', label: 'Autism spectrum disorder' },
    { value: '15', label: "Asperger's syndrome" },
    { value: '16', label: 'Temporary disability after illness or accident' },
    { value: '17', label: 'Speech & Language Impairment' },
    { value: '93', label: 'Other physical disability' },
    { value: '94', label: 'Other specific LD (Dyspraxia)' },
    { value: '95', label: 'Other condition (e.g. Epilepsy, asthma, diabetes)' },
    { value: '96', label: 'Other LD' },
    { value: '97', label: 'Other disability' },
    { value: '98', label: 'Prefer not to say' },
    { value: '99', label: 'Not provided' },
]


export const destinationOptions = [
    { value: 1, label: messageResources.DESTINATION.PLUS16 },
    { value: 2, label: messageResources.DESTINATION.LESS16 },
    { value: 3, label: messageResources.DESTINATION.FURTHER },
    { value: 4, label: messageResources.DESTINATION.HEALTH },
    { value: 5, label: messageResources.DESTINATION.NEET },
    { value: 6, label: messageResources.DESTINATION.RETURNING },
    { value: 7, label: messageResources.DESTINATION.SUPPORTED },
    { value: 8, label: messageResources.DESTINATION.VOLUNTEERING }

]

export const kinshipOptions = [
    { value: 'Mother', label: 'Mother' },
    { value: 'Father', label: 'Father' },
    { value: 'Spouse', label: 'Spouse' },
    { value: 'Brother', label: 'Brother' },
    { value: 'Sister', label: 'Sister' },
    { value: 'Cousin', label: 'Cousin' },
    { value: 'Aunt', label: 'Aunt' },
    { value: 'Foster Carer', label: 'Foster Carer' },
    { value: 'Uncle', label: 'Uncle' },
    { value: 'Grandparent', label: 'Grandparent' },
]


export const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
]

export const docTypeOptions = [
    { value: 'Academic Record', label: 'Academic Record' },
    { value: 'Annual Review Form', label: 'Annual Review Form' },
    { value: 'Application Form', label: 'Application Form' },
    { value: 'Communication Passport', label: 'Communication Passport' },
    { value: 'Immigration Status', label: 'Immigration Status' },
    { value: 'Medical Record', label: 'Medical Record' },
    { value: 'One Page Profile', label: 'One Page Profile' },
    { value: 'Photo Consent', label: 'Photo Consent' },
    { value: 'Risk Assessment', label: 'Risk Assessment' },
    { value: 'WRAT results', label: 'WRAT results' },
    
]

export const careerDocTypeOptions = [
    { value: messageResources.DOCUMENTS.CASE_1, label: messageResources.DOCUMENTS.CASE_1 },
    { value: messageResources.DOCUMENTS.CASE_2, label: messageResources.DOCUMENTS.CASE_2 },
    { value: messageResources.DOCUMENTS.CASE_3, label: messageResources.DOCUMENTS.CASE_3 },
    { value: messageResources.DOCUMENTS.CASE_4, label: messageResources.DOCUMENTS.CASE_4 },
    { value: messageResources.DOCUMENTS.CASE_5, label: messageResources.DOCUMENTS.CASE_5 },
    { value: messageResources.DOCUMENTS.CASE_6, label: messageResources.DOCUMENTS.CASE_6 },
    { value: messageResources.DOCUMENTS.CONTRACT_1, label: messageResources.DOCUMENTS.CONTRACT_1 },
    { value: messageResources.DOCUMENTS.CONTRACT_2, label: messageResources.DOCUMENTS.CONTRACT_2 },
    { value: messageResources.DOCUMENTS.CONTRACT_3, label: messageResources.DOCUMENTS.CONTRACT_3 },
    { value: 'Employer Review One', label: 'Employer Review One' },
    { value: 'Employer Review Two', label: 'Employer Review Two' },
    { value: 'Employer Review Three', label: 'Employer Review Three' },
    { value: 'WP Risk Assessment One', label: 'WP Risk Assessment One' },
    { value: 'WP Risk Assessment Two', label: 'WP Risk Assessment Two' },
    { value: 'WP Risk Assessment Three', label: 'WP Risk Assessment Three' },
]


export const yearOptions = [
    { value: messageResources.YEAR.Y20, label: messageResources.YEAR.Y20 },
    { value: messageResources.YEAR.Y21, label: messageResources.YEAR.Y21 },
    { value: messageResources.YEAR.Y22, label: messageResources.YEAR.Y22 },
    { value: messageResources.YEAR.Y23, label: messageResources.YEAR.Y23 },
    { value: messageResources.YEAR.Y24, label: messageResources.YEAR.Y24 },
    { value: messageResources.YEAR.Y25, label: messageResources.YEAR.Y25 },
    { value: messageResources.YEAR.Y26, label: messageResources.YEAR.Y26 }
]

export const yearChartOptions = [
    { value: messageResources.YEAR.Y22, label: messageResources.YEAR.Y22 },
    { value: messageResources.YEAR.Y23, label: messageResources.YEAR.Y23 }
]



export const subjectOptions = [
    { value: 1, label: 'English' },
    { value: 2, label: 'Maths' },
    { value: 3, label: 'Retail' },
    { value: 4, label: 'Floristry' },
    { value: 5, label: 'ICT' },
    { value: 6, label: 'Employability Skills' },
    { value: 7, label: 'Horticulture' },
    { value: 8, label: 'Enritchment' },
    { value: 9, label: 'Practise Skills' },
    { value: 10, label: 'Job Coaching' },
    { value: 11, label: 'Art' },
    { value: 12, label: 'Functional Skills' },
    { value: 13, label: 'Catering' },
    { value: 14, label: 'Employability' },
    
]

export const achievementOptions = [
    { value: 1, label: 'Achieved' },
    { value: 2, label: 'Not Achieved' },
    { value: 3, label: 'Partial Achievement' },
    { value: 4, label: 'Learning Continued' },
    { value: 5, label: 'Withdrawn' },
    
]


export const trackerOptions = [
    { value: 1, label: 'Access to Serrots' },
    { value: 2, label: 'Close Session' },
    { value: 3, label: 'Document Added' },
    { value: 4, label: 'Document Amended' },
    { value: 5, label: 'Programme Added' },
    { value: 6, label: 'Programme Amended' },
    { value: 7, label: 'Student Added' },
    { value: 8, label: 'Student Amended' }
    
]

export const roleOptions = [
    { value: 1, label: 'USER' }, 
    { value: 2, label: 'ADMINISTRATOR' },
    { value: 3, label: 'STUDENT REGISTER' },
    { value: 4, label: 'STUDENT EHCP' },
    { value: 5, label: 'STUDENT EDIT NO NOTIS' },
    { value: 6, label: 'EXTERNAL' },
    
]


export const medicalOptions = [
    { value: 'Autism', label: 'Autism' },
    { value: 'Allergies', label: 'Allergies' },
    { value: 'Allergies (Serious)', label: 'Allergies (Serious)' },
    { value: 'Asthma', label: 'Asthma' },
    { value: 'Asthma (Serious)', label: 'Asthma (Serious)' },
    { value: 'Anxiety with Dogs (Serious)', label: 'Anxiety with Dogs (Serious)' },
    { value: 'Anxiety to Heights (Serious)', label: 'Asthma to Heights (Serious)' },
    { value: 'Anxiety in Busy Locations (Serious)', label: 'Anxiety in Busy Locations (Serious)' },
    { value: 'Anxiety on the Underground (Serious)', label: 'Anxiety on the Underground (Serious)' },
    { value: 'Anxiety on Public Transport (Serious)', label: 'Anxiety on Public Transport (Serious)' },
    { value: 'Auditory Processing Disorder', label: 'Auditory Processing Disorder' },
    { value: 'Cardiac (Serious)', label: 'Cardiac (Serious)' },
    { value: 'Coordination Disorder', label: 'Coordination Disorder' },
    { value: 'Developmental Coordination Disorder', label: 'Developmental Coordination Disorder' },
    { value: 'Dysgraphia', label: 'Dysgraphia' },
    { value: 'Dyslexia', label: 'Dyslexia' },
    { value: 'Dyscalculia', label: 'Dyscalculia' },
    { value: 'Epilepsy', label: 'Epilepsy' },
    { value: 'Epilepsy (Serious)', label: 'Epilepsy (Serious)' },
    { value: 'Hearing Impairment', label: 'Hearing Impairment' },
    { value: 'Hearing Impairment (Serious)', label: 'Hearing Impairment (Serious)' },
    { value: 'Learning Difficulties', label: 'Learning Difficulties' },
    { value: 'Language Processing Disorder', label: 'Language Processing Disorder' },
    { value: 'Mental Health', label: 'Mental Health' },
    { value: 'Mental Health (Serious)', label: 'Mental Health (Serious)' },
    { value: 'Non-Verbal Learning Disabilities', label: 'Non-Verbal Learning Disabilities' },
    { value: 'Other', label: 'Other' },
    { value: 'Seizure Disorders/Tremors (Serious)', label: 'Seizure Disorders/Tremors (Serious)' },
    { value: 'Type 1 Diabetes (Serious)', label: 'Type 1 Diabetes (Serious)' },
    { value: 'Visual Processing Disorder', label: 'Visual Processing Disorder' },
    { value: 'Visual Impairment (Serious)', label: 'Visual Impairment (Serious)' },
    { value: 'Visual Motor Deficit', label: 'Visual Motor Deficit' },
    { value: 'Social Anxiety (Serious)', label: 'Social Anxiety (Serious)' },
    { value: 'Food Related Anxiety (Serious)', label: 'Food Related Anxiety (Serious)' }
    
]

export const positionOptions = [
    { value: 'Director', label: 'Director' },
    { value: 'Chair of Trustees', label: 'Chair of Trustees' },
    { value: 'Education Manager', label: 'Education Manager' },
    { value: 'Operations Manager', label: 'Operations Manager' },
    { value: 'Admin Support', label: 'Admin Support' },
    { value: 'Office & Events Coordinator', label: 'Office & Events Coordinator' },
    { value: 'SEND Coordinator', label: 'SEND Coordinator' },
    { value: 'LSA', label: 'LSA' },
    { value: 'LSA & Admin', label: 'LSA & Admin' },
    { value: 'Catering Tutor', label: 'Catering Tutor' },
    { value: 'Cleaner', label: 'Cleaner' },
    { value: 'Caretaker', label: 'Caretaker' },
    { value: 'Learning & Media Support', label: 'Learning & Media Support' },
    { value: 'SI Horti Tutor', label: 'SI Horti Tutor' },
    { value: 'SALT', label: 'SALT' },
    { value: 'Art Therapist', label: 'Art Therapist' },
    { value: 'Education & Careers Officer', label: 'Education & Careers Officer' },
    { value: 'Floral Design Tutor', label: 'Floral Design Tutor' },
    { value: 'Employability Tutor', label: 'Employability Tutor' },
    { value: 'Horticulture Tutor', label: 'Horticulture Tutor' },
    { value: 'Functional Skills Tutor', label: 'Functional Skills Tutor' },
    { value: 'Employability & Horticulture Tutor', label: 'Employability & Horticulture Tutor' },
    { value: 'School Links Tutor & Job Coach', label: 'School Links Tutor & Job Coach' },
    { value: 'Music Tutor', label: 'Music Tutor' },
    { value: 'Job Coach & LSA', label: 'Job Coach & LSA' },
    { value: 'Job Coach & Careers Admin', label: 'Job Coach & Careers Admin' },
    { value: 'Job Coach', label: 'Job Coach' },
    { value: 'IT', label: 'IT' },
    { value: 'School Links Tutor', label: 'School Links Tutor' },
    { value: 'Pre-Si Tutor', label: 'Pre-Si Tutor' },
    { value: 'Tutor', label: 'Tutor' },
    { value: 'Careers Lead', label: 'Careers Lead' },
    { value: 'LSA & Shop Supervisor', label: 'LSA & Shop Supervisor' },
    { value: 'Office Manager', label: 'Office Manager' },
    { value: 'Venue Coordinator', label: 'Venue Coordinator' },
    { value: 'Education Administrator', label: 'Education Administrator' },
    { value: 'Communications Officer', label: 'Communications Officer' },
    { value: 'Senior Administrator', label: 'Senior Administrator' },
]

export const ethnicitiesOptions = [
    {value: 'White British', label: 'White British'},
    {value: 'Other White', label: 'Other White'},
    {value: 'Mixed/Multiple', label: 'Mixed/Multiple'},
    {value: 'Indian', label: 'Indian'},
    {value: 'Pakistani', label: 'Pakistani'},
    {value: 'Bangladeshi', label: 'Bangladeshi'},
    {value: 'Other Asian background', label: 'Other Asian background'},
    {value: 'Black Caribbean', label: 'Black Caribbean'},
    {value: 'Black African', label: 'Black African'},
    {value: 'Chinese', label: 'Chinese'},
    {value: 'Other', label: 'Other'}
]

export const boroughs = [

    {value: 'Barking and Dagenham', label: 'Barking and Dagenham'},
    {value: 'Barnet', label: 'Barnet'},
    {value: 'Bexley', label: 'Bexley'},
    {value: 'Brent', label: 'Brent'},
    {value: 'Bromley', label: 'Bromley'},
    {value: 'Camden', label: 'Camden'},
    {value: 'City of London', label: 'City of London'},
    {value: 'Croydon', label: 'Croydon'},
    {value: 'Ealing', label: 'Ealing'},
    {value: 'Enfield', label: 'Enfield'},
    {value: 'Greenwich', label: 'Greenwich'},
    {value: 'Hammersmith and Fulham', label: 'Hammersmith and Fulham'},
    {value: 'Haringey', label: 'Haringey'},
    {value: 'Harrow', label: 'Harrow'},
    {value: 'Havering', label: 'Havering'},
    {value: 'Hillingdon', label: 'Hillingdon'},
    {value: 'Hounslow', label: 'Hounslow'},
    {value: 'Islington', label: 'Islington'},
    {value: 'Kensington and Chelsea', label: 'Kensington and Chelsea'},
    {value: 'Kingston upon Thames', label: 'Kingston upon Thames'},
    {value: 'Lambeth', label: 'Lambeth'},
    {value: 'Lewisham', label: 'Lewisham'},
    {value: 'Merton', label: 'Merton'},
    {value: 'Newham', label: 'Newham'},
    {value: 'Redbridge', label: 'Redbridge'},
    {value: 'Richmond upon Thames', label: 'Richmond upon Thames'},
    {value: 'Southwark', label: 'Southwark'},
    {value: 'Sutton', label: 'Sutton'},
    {value: 'Tower Hamlets', label: 'Tower Hamlets'},
    {value: 'Waltham Forest', label: 'Waltham Forest'},
    {value: 'Wandsworth', label: 'Wandsworth'},
    {value: 'Westminster', label: 'Westminster'}

]

export const resultOptions = [
    { value: 1, label: 'Entry Level' },
    { value: 2, label: 'Level 1' },
    { value: 3, label: 'Level 2' },
    { value: 5, label: 'Level 3' },
    { value: 4, label: 'Pre-Entry' },
]

export const assessmentOptions = [
    { value: 1, label: 'English' },
    { value: 2, label: 'Maths' },
]


export const attainmentOptions = [
    { value: 1, label: 'Level 1' },
    { value: 2, label: 'Full Level 2' },
    { value: 3, label: 'Full Level 3' },
    { value: 7, label: 'Other Sub Level 1' },
    { value: 9, label: 'Entry Level' },
    { value: 10, label: 'Level 4' },
    { value: 11, label: 'Level 5' },
    { value: 12, label: 'Level 6' },
    { value: 13, label: 'Level 7 & Above' },
    { value: 97, label: 'Other' },
    { value: 98, label: 'Not Known' },
    { value: 99, label: 'None' },
]


export const progamOptions = [

    {value: 1, label: messageResources.PROGRAM.STUDY},
    {value: 2, label: messageResources.PROGRAM.PRESUP},
    {value: 3, label: messageResources.PROGRAM.NON}

]

export const specialistOptions = [

    {value: messageResources.VOCATIONAL.FLORITRY, label: messageResources.VOCATIONAL.FLORITRY},
    {value: messageResources.VOCATIONAL.HORTI, label: messageResources.VOCATIONAL.HORTI},
    {value: messageResources.VOCATIONAL.HOSPI, label: messageResources.VOCATIONAL.HOSPI},
    {value: messageResources.VOCATIONAL.RETAIL, label: messageResources.VOCATIONAL.RETAIL},
    {value: messageResources.VOCATIONAL.RETHOS, label: messageResources.VOCATIONAL.RETHOS},
    {value: messageResources.VOCATIONAL.HORTRET, label: messageResources.VOCATIONAL.HORTRET},
    {value: messageResources.VOCATIONAL.CUSTOMER, label: messageResources.VOCATIONAL.CUSTOMER}
    
]


export const aimOptions = [

    { value: '60039486', label: 'City & Guilds Certificate L1 in Retail Skills' },
    { value: '60056113', label: 'City & Guilds Certificate L1 in Practical Horticulture Skills' },
    { value: '6005587X', label: 'City & Guilds Award L1 in Practical Horticulture' },
    { value: '60044627', label: 'City & Guilds Award L1 in Floral Design (Contemporary Flower Arrangement-Table Décor)'},
    { value: '50118390', label: 'City and Guilds Level 1 Award for Introduction to Customer Service'},
    { value: '50118377', label: 'City and Guilds Level 1 Certificate for Introduction to Customer Service'},
    { value: '50065130', label: 'City and Guilds Entry Level 3 Award in Introduction to the Hospitality Industry'},
    { value: 'Z0001822', label: 'Non Regulated Provision L1 Foundations for Learning and Life'},
    { value: '6004/C2', label: 'WJEC Entry 2 Certificate in Science Today'},
    { value: '6004/A3', label: 'WJEC Entry 3 Award in Science Today'},
    { value: '60044615', label: 'City & Guilds Award L1 in Floral Design (Contemporary Flower Arrangement-Free Standing)'},
    { value: 'Z0001734_1', label: 'WJEC Entry 2 Award in Science Today'},
    { value: 'Z0001734_2', label: 'WJEC Entry 2 Diploma in Science Today'},
    { value: 'Z0001805', label: 'Roots and Shoots Level 1 Award in Floristry - Table Arrangements'},
    { value: 'Z0001805_1', label: 'Roots and Shoots Level 1 Award in Floristry - Wedding'},
    { value: 'Z0001805_2', label: 'Roots and Shoots Level 1 Certificate in Floristry'},
    { value: 'Z0001737', label: 'Roots and Shoots Entry 2 Award in Practical Horticulture'},
    { value: 'Z0001737_1', label: 'Roots and Shoots Entry 2 Certificate in Practical Horticulture'},
]

export const internshipActivitiesOptions = [
    {value: 'Z0001822', label: 'Non regulated provision - Level 1 - Foundations for Learning and Life'},
    {value: 'Z0001823', label: 'Non regulated provision - Level 1 - Preparation for Work'},
    {value: 'ZWRKX001', label: 'Work experience (London Wildlife Trust Centre for Wildlife Gardening)'},
    {value: 'ZWRKX001_1', label: "Work experience (Archbishop's Park)"},
    {value: 'ZWRKX001_2', label: 'Work experience (Pets at Home)'},
    {value: 'ZWRKX001_3', label: "Work experience (St John's Church)"},
    {value: 'ZWRKX001_4', label: "Work experience (Tesco)"},
    {value: 'ZWRKX001_5', label: "Work experience (St Marks's Church)"},
    {value: 'ZWRKX001_6', label: "Work experience (Weir Link Nursery)"},
    {value: 'ZWRKX001_7', label: "Work experience (Ethelred Nursery)"},
    {value: 'ZWRKX001_8', label: "Work experience (Whippersnappers)"},
    {value: 'ZWRKX001_9', label: "Work experience (Eataly)"},
    {value: 'ZWRKX001_10', label: "Work experience (Travel Cafe)"},
    {value: 'ZWRKX001_11', label: "Work experience (KataKata)"},
    {value: 'ZWRKX001_12', label: "Work experience (Marks & Spencer)"},
    {value: 'ZWRKX001_13', label: "Work experience (Archbishop Summer COFE Primary School)"},
    {value: 'ZWRKX001_14', label: "Work experience (Archbishop's Park Community Trust)"},
    {value: 'ZWRKX001_15', label: "Work experience (Beaconsfield Gallery)"},
    {value: 'ZWRKX001_16', label: "Work experience (Bede House)"},
    {value: 'ZWRKX001_17', label: "Work experience (Brockwell Communty Greenhouses)"},
    {value: 'ZWRKX001_18', label: "Work experience (Disability Sports Coach)"},
    {value: 'ZWRKX001_19', label: "Work experience (Imperial War Museum)"},
    {value: 'ZWRKX001_20', label: "Work experience (Lambeth Head Quarters Police)"},
    {value: 'ZWRKX001_21', label: "Work experience (London Transport Museum)"},
    {value: 'ZWRKX001_22', label: "Work experience (Lumberjack Cafe)"},
    {value: 'ZWRKX001_23', label: "Work experience (The Platform Cafe)"},
    {value: 'ZWRKX001_24', label: "Work experience (Simply Bread)"},
    {value: 'ZWRKX001_25', label: "Work experience (The Jolly Gardeners)"},
    {value: 'ZWRKX001_27', label: "Work experience (Walworth Garden)"},
    {value: 'ZWRKX001_28', label: "Work experience (Waterloo Action Centre)"},
    {value: 'ZWRKX001_29', label: "Work experience (Young Vic Theatre)"},
    {value: 'ZWRKX001_30', label: "Work experience (Cafe Van Gogh)"},
    {value: 'ZWRKX001_31', label: "Work experience (Garden Museum)"},
    {value: 'ZWRKX001_32', label: "Work experience (Bouquets and Beans)"},
    {value: 'ZWRKX001_33', label: "Work experience (Sports Coach Assistant Fitzroy Lodge)"},
    {value: 'ZWRKX001_34', label: 'Internal Work Placement (Roots Services - Shop)'},
    {value: 'ZWRKX001_35', label: 'Internal Work Placement (Cleaning)'},
    {value: 'ZWRKX001_36', label: 'Internal Work Placement (Learning Support)'},
    {value: 'ZWRKX001_37', label: 'Internal Work Placement (Roots Services - Site Maintenance)'},
    {value: 'ZWRKX001_38', label: 'Internal Work Placement (Catering)'},
    {value: 'ZWRKX001_39', label: 'Internal Work Placement (LSA)'},
    {value: 'ZWRKX001_40', label: 'Internal Work Placement (Sports Coach Assistant)'},
    {value: 'ZWRKX001_41', label: 'Work experience (Hilton)'},
    {value: 'ZWRKX001_42', label: 'Work experience (Marsden Road Wildlife Garden)'},
    {value: 'ZWRKX001_43', label: 'Work experience (Bee Urban)'},
    {value: 'ZWRKX001_44', label: 'Work experience (The Hive Cafe)'},
    {value: 'ZWRKX001_45', label: 'Work experience (Bonington Cafe)'},
    {value: 'ZWRKX001_46', label: 'Internal Work Placement (Hospitality - Retail)'},
    {value: 'ZWRKX001_47', label: 'Work experience (The Glitch)'},
    {value: 'ZWRKX001_48', label: 'Work experience (The Woodland Trust Dulwich Upper Wood)'},
    {value: '00000007', label: 'SALT'},
    {value: '00000009', label: 'Art'},
    {value: '00000012', label: 'Art Therapy'},
    {value: '00000001', label: 'Practical horticulture'},
    {value: '00000003', label: 'Job Coaching'},
    {value: '00000004', label: 'PSD Workshops'},
    {value: '00000002', label: 'Autism support'},
    {value: '00000006', label: 'Counselling'},
    {value: '00000005', label: 'Literacy Specialism'},
    {value: '00000010', label: 'Personal and Social Development Workshops'},
    {value: '00000011', label: 'Multi Sports'},
    {value: '00000013', label: 'Practical Floristry Skills'},
    {value: '00000014', label: 'Practical Customer Service Skills'},
    {value: 'Z0002135', label: 'Roots and Shoots- Award in Writing Level 1'},
    {value: 'Z0002135_1', label: 'Roots and Shoots- Award in Speaking and Listening Level 1'},
    {value: 'Z0002136', label: 'Roots and Shoots- Award in Reading Level 2'},
    {value: 'Z0002136_1', label: 'Roots and Shoots- Award in Writing Level 2'},
    {value: 'Z0002136_2', label: 'Roots and Shoots- Award in Speaking and Listening Level 2'},
    {value: 'Z0002139', label: 'Roots and Shoots- Award in Number Level 1'},
    {value: 'Z0002139_1', label: 'Roots and Shoots - Award in Handling Data Level 1'},
    {value: 'Z0002139_2', label: 'Roots and Shoots - Award in Measure, Shape and Space Level 1'},
    {value: 'Z0002138', label: 'Roots and Shoots - Award in Handling Data Entry 1'},
    {value: 'Z0002138_1', label: 'Roots and Shoots - Award in Handling Data Entry 2'},
    {value: 'Z0002138_2', label: 'Roots and Shoots - Award in Handling Data Entry 3'},
    {value: 'Z0002140', label: 'Roots and Shoots- Award in Number Level 2'},
    {value: 'Z0002140_1', label: 'Roots and Shoots- Award in Handling Data Level 2'},
    {value: 'Z0002140_2', label: 'Roots and Shoots- Award in Measure, Shape and Space Level 2 '},
    {value: 'Z0001805', label: 'Roots and Shoots Level 1 Award in Floristry - Table Arrangements'},
    {value: 'Z0001805_1', label: 'Roots and Shoots Level 1 Award in Floristry - Wedding'},
    {value: 'Z0001805_2', label: 'Roots and Shoots Level 1 Certificate in Floristry'},
    {value: 'Z0001737', label: 'Roots and Shoots Entry 2 Award in Practical Horticulture'},
    {value: 'Z0001737_1', label: 'Roots and Shoots Entry 2 Certificate in Practical Horticulture'},
    
]

export const plannedEppOptions = [
    {value: 'Z0001734', label: 'WJEC Entry 2 Certificate in Science Today'},
    {value: 'Z0001734_1', label: 'WJEC Entry 2 Award in Science Today'},
    {value: 'Z0001734_2', label: 'WJEC Entry 2 Diploma in Science Today'},
    {value: 'Z0001734_3', label: 'WJEC Entry 3 Award in Science Today'},
    {value: 'Z0001787', label: 'City and Guilds Level 1 Award in Floral Design (Contemporary Flower Arrangement - Table Décor)'},
    {value: 'Z0001787_1', label: 'City and Guilds Level 1 Award in Floral Design (Contemporary Flower Arrangement - Free Standing)'},
    {value: 'Z0001787_2', label: 'City and Guilds Level 1 Certificate in Floral Design'},
    {value: '00000001', label: 'Practical horticulture'},
    {value: '00000008', label: 'Health and fitness'},
    {value: '00000003', label: 'Job Coaching'},
    {value: '00000004', label: 'PSD Workshops'},
    {value: '00000005', label: 'Literacy Specialism'},
    {value: '00000006', label: 'Counselling'},
    {value: '00000007', label: 'SALT'},
    {value: '00000009', label: 'Art'},
    {value: '00000012', label: 'Art Therapy'},
    {value: '00000011', label: 'Multi Sports'},
    {value: '00000010', label: 'Personal and Social Development Workshops'},
    {value: '00000002', label: 'Autism support'},
    {value: '00000013', label: 'Practical Floristry Skills'},
    {value: '00000014', label: 'Practical Customer Service Skills'},
    {value: 'Z0001735', label: 'Award in Maths Skills'},
    {value: 'Z0001767', label: 'Award in English Skills'},
    {value: '00000008', label: 'Practical Retail'},
    {value: 'ZWRKX001', label: 'Work experience (London Wildlife Trust Centre for Wildlife Gardening)'},
    {value: 'ZWRKX001_1', label: "Work experience (Archbishop's Park)"},
    {value: 'ZWRKX001_2', label: 'Work experience (Pets at Home)'},
    {value: 'ZWRKX001_3', label: "Work experience (St John's Church)"},
    {value: 'ZWRKX001_4', label: "Work experience (Tesco)"},
    {value: 'ZWRKX001_5', label: "Work experience (St Marks's Church)"},
    {value: 'ZWRKX001_6', label: "Work experience (Weir Link Nursery)"},
    {value: 'ZWRKX001_7', label: "Work experience (Ethelred Nursery)"},
    {value: 'ZWRKX001_8', label: "Work experience (Whippersnappers)"},
    {value: 'ZWRKX001_9', label: "Work experience (Eataly)"},
    {value: 'ZWRKX001_10', label: "Work experience (Travel Cafe)"},
    {value: 'ZWRKX001_11', label: "Work experience (KataKata)"},
    {value: 'ZWRKX001_12', label: "Work experience (Marks & Spencer)"},
    {value: 'ZWRKX001_13', label: "Work experience (Archbishop Summer COFE Primary School)"},
    {value: 'ZWRKX001_14', label: "Work experience (Archbishop's Park Community Trust)"},
    {value: 'ZWRKX001_15', label: "Work experience (Beaconsfield Gallery)"},
    {value: 'ZWRKX001_16', label: "Work experience (Bede House)"},
    {value: 'ZWRKX001_17', label: "Work experience (Brockwell Communty Greenhouses)"},
    {value: 'ZWRKX001_18', label: "Work experience (Disability Sports Coach)"},
    {value: 'ZWRKX001_19', label: "Work experience (Imperial War Museum)"},
    {value: 'ZWRKX001_20', label: "Work experience (Lambeth Head Quarters Police)"},
    {value: 'ZWRKX001_21', label: "Work experience (London Transport Museum)"},
    {value: 'ZWRKX001_22', label: "Work experience (Lumberjack Cafe)"},
    {value: 'ZWRKX001_23', label: "Work experience (The Platform Cafe)"},
    {value: 'ZWRKX001_24', label: "Work experience (Simply Bread)"},
    {value: 'ZWRKX001_25', label: "Work experience (The Jolly Gardeners)"},
    {value: 'ZWRKX001_27', label: "Work experience (Walworth Garden)"},
    {value: 'ZWRKX001_28', label: "Work experience (Waterloo Action Centre)"},
    {value: 'ZWRKX001_29', label: "Work experience (Young Vic Theatre)"},
    {value: 'ZWRKX001_30', label: "Work experience (Cafe Van Gogh)"},
    {value: 'ZWRKX001_31', label: "Work experience (Garden Museum)"},
    {value: 'ZWRKX001_32', label: "Work experience (Bouquets and Beans)"},
    {value: 'ZWRKX001_33', label: "Work experience (Sports Coach Assistant Fitzroy Lodge)"},
    {value: 'ZWRKX001_34', label: 'Internal Work Placement (Roots Services - Shop)'},
    {value: 'ZWRKX001_35', label: 'Internal Work Placement (Cleaning)'},
    {value: 'ZWRKX001_36', label: 'Internal Work Placement (Learning Support)'},
    {value: 'ZWRKX001_37', label: 'Internal Work Placement (Roots Services - Site Maintenance)'},
    {value: 'ZWRKX001_38', label: 'Internal Work Placement (Catering)'},
    {value: 'ZWRKX001_39', label: 'Internal Work Placement (LSA)'},
    {value: 'ZWRKX001_40', label: 'Internal Work Placement (Sports Coach Assistant)'},
    {value: 'ZWRKX001_41', label: 'Work experience (Hilton)'},
    {value: 'ZWRKX001_42', label: 'Work experience (Marsden Road Wildlife Garden)'},
    {value: 'ZWRKX001_43', label: 'Work experience (Bee Urban)'},
    {value: 'ZWRKX001_44', label: 'Work experience (The Hive Cafe)'},
    {value: 'ZWRKX001_45', label: 'Work experience (Bonington Cafe)'},
    {value: 'ZWRKX001_46', label: 'Internal Work Placement (Hospitality - Retail)'},
    {value: 'ZWRKX001_47', label: 'Work experience (The Glitch)'},
    {value: 'ZWRKX001_48', label: 'Work experience (The Woodland Trust Dulwich Upper Wood)'},
    {value: 'Z0001785', label: 'City and Guilds Award in Maths Skills - Number Level 1'},
    {value: 'Z0001835', label: 'City and Guilds Award in Maths Skills - Number Level 2'},
    {value: 'Z0001735_1', label: 'City and Guilds Award in Mathematics Skills - Handling Data Entry 1'},
    {value: 'Z0001735_2', label: 'City and Guilds Award in Mathematics Skills - Handling Data Entry 2'},
    {value: 'Z0001735_3', label: 'City and Guilds Award in Mathematics Skills - Handling Data Entry 3'},
    {value: 'Z0001785_2', label: 'City and Guilds Award in Mathematics Skills - Handling Data Level 1'},
    {value: 'Z0001835_1', label: 'City and Guilds Award in Mathematics Skills - Handling Data Level 2'},
    {value: 'Z0001785_3', label: 'City and Guilds Award in Mathematics Skills - Measure, Shape and Space Level 1'},
    {value: 'Z0001835_2', label: 'City and Guilds Award in Mathematics Skills Measure, Shape and Space Level 2 '},
    {value: 'Z0001867', label: 'City and Guilds Award in English Skills - Reading Level 2'},
    {value: 'Z0001817', label: 'City and Guilds Award in English Skills - Writing Level 1'},
    {value: 'Z0001867_1', label: 'City and Guilds Award in English Skills - Writing Level 2'},
    {value: 'Z0001817_1', label: 'City and Guilds Award in English Skills Speaking and Listening Level 1'},
    {value: 'Z0001867_2', label: 'City and Guilds Award in English Skills Speaking and Listening Level 2'},
    {value: 'Z0002135', label: 'Roots and Shoots- Award in Writing Level 1'},
    {value: 'Z0002135_1', label: 'Roots and Shoots- Award in Speaking and Listening Level 1'},
    {value: 'Z0002136', label: 'Roots and Shoots- Award in Reading Level 2'},
    {value: 'Z0002136_1', label: 'Roots and Shoots- Award in Writing Level 2'},
    {value: 'Z0002136_2', label: 'Roots and Shoots- Award in Speaking and Listening Level 2'},
    {value: 'Z0002139', label: 'Roots and Shoots- Award in Number Level 1'},
    {value: 'Z0002139_1', label: 'Roots and Shoots - Award in Handling Data Level 1'},
    {value: 'Z0002139_2', label: 'Roots and Shoots - Award in Measure, Shape and Space Level 1'},
    {value: 'Z0002138', label: 'Roots and Shoots - Award in Handling Data Entry 1'},
    {value: 'Z0002138_1', label: 'Roots and Shoots - Award in Handling Data Entry 2'},
    {value: 'Z0002138_2', label: 'Roots and Shoots - Award in Handling Data Entry 3'},
    {value: 'Z0002140', label: 'Roots and Shoots- Award in Number Level 2'},
    {value: 'Z0002140_1', label: 'Roots and Shoots- Award in Handling Data Level 2'},
    {value: 'Z0002140_2', label: 'Roots and Shoots- Award in Measure, Shape and Space Level 2 '},
    {value: 'Z0001805', label: 'Roots and Shoots Level 1 Award in Floristry - Table Arrangements'},
    {value: 'Z0001805_1', label: 'Roots and Shoots Level 1 Award in Floristry - Wedding'},
    {value: 'Z0001805_2', label: 'Roots and Shoots Level 1 Certificate in Floristry'},
    {value: 'Z0001737', label: 'Roots and Shoots Entry 2 Award in Practical Horticulture'},
    {value: 'Z0001737_1', label: 'Roots and Shoots Entry 2 Certificate in Practical Horticulture'},
]



export const cityGuildsOptions = [
    {value: '60136352', label: 'City and Guilds Extended Award in Employability Skills Entry 2'},
    {value: '60136388', label: 'City and Guilds Extended Certificate in Employability Skills Entry 2'},
    {value: '60075880', label: 'City and Guilds Award in English Skills - Reading Entry 1'},
    {value: '60136388_1', label: 'City and Guilds Extended Certificate in Employability Skills Entry 2'},
    {value: '6013642X', label: 'City and Guilds Extended Certificate in Employability Skills Entry 3'},
    {value: '60136340', label: 'City and Guilds Certificate in Employability Skills Entry 2'},
    {value: '60074942', label: 'City and Guilds Entry Level Award in Mathematics Skills Measure - Shape and Space Entry 2'},
    {value: '60076148', label: 'City and Guilds Award in English Skills - Writing Entry 1'},
    {value: '50085086', label: 'City and Guilds Functional Skills ICT at Entry 2'},
    {value: '50106387', label: 'City and Guilds Functional Skills ICT at Entry 3'},
    {value: '60074929', label: 'City and Guilds Entry Level Award in Mathematics Skills Measure - Shape and Space Entry 1'},
    {value: '60075892', label: 'City and Guilds Award in English Skills Speaking and Listening Entry 1'},
    {value: '6007498X', label: 'City and Guilds Entry Level Award in Mathematics Skills Measure - Shape and Space Entry 3'},
    // {value: '6005587X', label: 'City and Guilds Award in Practical Horticulture L1'},
    {value: '6005587X', label: 'City and Guilds Award L1 in Practical Horticulture Skills'},
    {value: '60136418', label: 'City and Guilds Certificate in Employability Skills Entry 3'},
    {value: '60076070', label: 'City and Guilds Award in English Skills - Writing Entry 3'},
    {value: '60075077', label: 'City and Guilds Award in Mathematics Skills - Handling Data Level 1'},
    {value: '60076021', label: 'City and Guilds Award in English Skills - Reading Level 1'},
    {value: '60136248', label: 'City and Guilds Award in Employability Skills Entry 2'},
    {value: '60136261', label: 'City and Guilds Award in Employability Skills Entry 3'},
    {value: '60056113', label: 'City and Guilds Level 1 Certificate in Practical Horticulture Skills'},
    {value: '60136273', label: 'City and Guilds Extended Award in Employability Skills E3'},
    {value: '60074966', label: 'City and Guilds Award in Maths Skills - Handling Data Entry 2'},
    {value: '60074991', label: 'City and Guilds Award in Maths Skills - Handling Data Entry 3'},
    {value: '60076094', label: 'City and Guilds Award in English Skills - Writing Entry 2'},
    {value: '60074930', label: 'City and Guilds Award in Maths Skills - Handling Data Entry 1'},
    {value: '6007615X', label: 'City and Guilds Award in English Skills - Reading Entry 2'},
    {value: '50085074', label: 'City and Guilds Functional Skills ICT at Entry 1'},
    {value: '60075247', label: 'City and Guilds Award in Mathematics Skills - Number Entry 1'},
    {value: '60074942_1', label: 'City and Guilds Award in Mathematics Skills - Number Entry 2'},
    {value: '60074978', label: 'City and Guilds Award in Mathematics Skills - Number Entry 3'},
    {value: '60056125', label: 'City and Guilds Level 1 Diploma in Practical Horticulture Skills'},
    {value: '60039486', label: 'City and Guilds Certificate in Retail Skills Level 1'},
    {value: '60076082', label: 'City and Guilds Award in English Skills - Reading Entry 3'},
    {value: '6007601X', label: 'City and Guilds Award in English Skills Speaking and Listening Entry 2'},
    {value: '60076008', label: 'City and Guilds Award in English Skills Speaking and Listening Entry 3'},
    {value: '50118390', label: 'City and Guilds Level 1 Award for Introduction to Customer Service'},
    {value: '50118377', label: 'City and Guilds Level 1 Certificate for Introduction to Customer Service'},
    {value: '50065130', label: 'City and Guilds Entry Level 3 Award in Introduction to the Hospitality Industry'}
    
]

export const dietaryOptions = [

    { value: 1, label: 'Halal Food' },
    { value: 2, label: 'Vegan' },
    { value: 3, label: 'Vegetarian' },
    { value: 4, label: 'Gluten Free' },
    { value: 5, label: 'Lactose Free' },
    { value: 7, label: 'Fish & Sea Food Free' },
    { value: 8, label: 'Seed Free' },
    { value: 9, label: 'Other (See Comments)' }
]


export const languagesSpokenOptions = [

    { value: 1, label: 'Spanish' },
    { value: 2, label: 'Portuguese' },
    { value: 3, label: 'French' },
    { value: 4, label: 'Somali' },
    { value: 5, label: 'Italian' },
    { value: 7, label: 'Dutch' },
    { value: 8, label: 'Russian' },
    { value: 9, label: 'Latvian' },
    { value: 10, label: 'Arabic' },
    { value: 11, label: 'Lingala' },
    { value: 12, label: 'Yoruba' },
    { value: 13, label: 'German' },
]



export const englishOptions = [

    { value: 1, label: 'Grade 1 English Language & English Literature' },
    { value: 2, label: 'Grade 2 English Language & English Literature' },
    { value: 3, label: 'Grade 3 English Language & English Literature' },
    { value: 4, label: 'Grade 4 English Language & English Literature' },
    { value: 5, label: 'Grade 5 English Language & English Literature' },
    { value: 6, label: 'Grade 6 English Language & English Literature' },
    { value: 7, label: 'Grade 7 English Language & English Literature' },
    { value: 8, label: 'Grade 8 English Language & English Literature' },
    { value: 9, label: 'Grade 9 English Language & English Literature' },
    { value: 10, label: 'Grade A English Language & English Literature' },
    { value: 11, label: 'Grade B English Language & English Literature' },
    { value: 12, label: 'Grade C English Language & English Literature' },
    { value: 13, label: 'Grade D English Language & English Literature' },
    { value: 14, label: 'Grade E English Language & English Literature' },
    { value: 15, label: 'Grade F English Language & English Literature' },
    { value: 16, label: 'Grade G English Language & English Literature' },
    { value: 17, label: 'Grade U English Language & English Literature' },
    { value: 18, label: 'Grade A* English Language & English Literature' },
    { value: 19, label: 'Grade 1 English Language' },
    { value: 20, label: 'Grade 2 English Language' },
    { value: 21, label: 'Grade 3 English Language' },
    { value: 22, label: 'Grade 4 English Language' },
    { value: 23, label: 'Grade 5 English Language' },
    { value: 24, label: 'Grade 6 English Language' },
    { value: 25, label: 'Grade 7 English Language' },
    { value: 26, label: 'Grade 8 English Language' },
    { value: 27, label: 'Grade 9 English Language' },
    { value: 28, label: 'Grade A English Language' },
    { value: 29, label: 'Grade B English Language' },
    { value: 30, label: 'Grade C English Language' },
    { value: 31, label: 'Grade D English Language' },
    { value: 32, label: 'Grade E English Language' },
    { value: 33, label: 'Grade F English Language' },
    { value: 34, label: 'Grade G English Language' },
    { value: 35, label: 'Grade U English Language' },
    { value: 36, label: 'Grade A+ English Language' },
    { value: 37, label: 'Grade 1 English Literature' },
    { value: 38, label: 'Grade 2 English Literature' },
    { value: 39, label: 'Grade 3 English Literature' },
    { value: 40, label: 'Grade 4 English Literature' },
    { value: 41, label: 'Grade 5 English Literature' },
    { value: 42, label: 'Grade 6 English Literature' },
    { value: 43, label: 'Grade 7 English Literature' },
    { value: 44, label: 'Grade 8 English Literature' },
    { value: 45, label: 'Grade 9 English Literature' },
    { value: 46, label: 'Grade A English Literature' },
    { value: 47, label: 'Grade B English Literature' },
    { value: 48, label: 'Grade C English Literature' },
    { value: 49, label: 'Grade D English Literature' },
    { value: 50, label: 'Grade E English Literature' },
    { value: 51, label: 'Grade F English Literature' },
    { value: 52, label: 'Grade G English Literature' },
    { value: 53, label: 'Grade U English Literature' },
    { value: 54, label: 'Grade A+ English Literature' },
    

]

export const mathOptions = [

    { value: 1, label: 'GCSE in Maths Grade 1' },
    { value: 2, label: 'GCSE in Maths Grade 2' },
    { value: 3, label: 'GCSE in Maths Grade 3' },
    { value: 4, label: 'GCSE in Maths Grade 4' },
    { value: 5, label: 'GCSE in Maths Grade 5' },
    { value: 6, label: 'GCSE in Maths Grade 6' },
    { value: 7, label: 'GCSE in Maths Grade 7' },
    { value: 8, label: 'GCSE in Maths Grade 8' },
    { value: 9, label: 'GCSE in Maths Grade 9' },
    { value: 10, label: 'GCSE in Maths Grade A' },
    { value: 11, label: 'GCSE in Maths Grade B' },
    { value: 12, label: 'GCSE in Maths Grade C' },
    { value: 13, label: 'GCSE in Maths Grade D' },
    { value: 14, label: 'GCSE in Maths Grade E' },
    { value: 15, label: 'GCSE in Maths Grade F' },
    { value: 16, label: 'GCSE in Maths Grade G' },
    { value: 17, label: 'GCSE in Maths Grade U' },
    { value: 18, label: 'GCSE in Maths Grade A+' },

]

export const mathExemOptions = [
    { value: 1, label: 'Exempt from GCSE Maths Condition of Funding Due to a Learning Difficulty' },
    { value: 2, label: 'Met GCSE Maths Condition of Funding as they Hold an Approved Equivalent UK' },
]

export const englishExemOptions = [
    { value: 1, label: 'Exempt from GCSE English Condition of Funding Due to a Learning Difficulty' },
    { value: 2, label: 'Met GCSE English Condition of Funding as they Hold an Approved Equivalent UK' },
]


